/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./header"
import FrenchHeader from "./header-fr"
import "./layout.css"

class Layout extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = { language:"en"};
    this.setLanguage=this.setLanguage.bind(this)
  }
  setLanguage(newLanguage){
    this.setState({language: newLanguage})
  }
  componentDidMount() {
    const { language } = this.props;
    this.setLanguage(language);
  }
  componentWillReceiveProps(nextProps){
    if (nextProps.language !== this.props.language) {
      this.setLanguage(nextProps.language)
    }
  }
  
render(){
  const {children}=this.props;
  if (this.state.language==="fr"){return (
    <>
      <FrenchHeader setLanguage={this.setLanguage} linkColor={this.props.linkColor} siteTitle="Jonar Labs" siteDescription="Une équipe diversifiée de designers, innovateurs, développeurs et penseurs qui remettent constamment en question le statu quo. Jonar a ouvert ses portes en 1986 et se trouve à Montreal, Quebec." />
      <div
        style={{
          margin: `0 auto`,
          paddingTop: 0,
        }}
      >
        <main>{children}</main>
      </div>
      <footer>
      </footer>
    </>
  )}
  else return (
    <>
      <Header setLanguage={this.setLanguage} linkColor={this.props.linkColor} siteTitle="Jonar Labs" siteDescription="We are a passionate team of designers, developers, and innovators dedicated to delivering solutions that help businesses work better.  Founded in 1986, Jonar is an award-winning software company operating out of Montreal, Quebec." />
      <div
        style={{
          margin: `0 auto`,
          paddingTop: 0,
        }}
      >
        <main>{children}</main>
      </div>
      <footer>
      </footer>
    </>
  )}
};

export default Layout
