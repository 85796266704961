import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'


import jonarLabs from '../images/labsLogo.png'

var frenchPage;
var englishPage;

class Header extends React.Component{ 

  constructor(props) {
    super(props);}

  render() {
  
    if (typeof window !== 'undefined'){
  englishPage=window.location.pathname;
  frenchPage= "/fr"+window.location.pathname;
  }
  
 
  return(
  <header>
    <Navbar bg="gray" variant="light" expand="md" id="top" className="bootyNav">
    <Link to="/"><Navbar.Brand className="ParagonERP">
    <img
        src={jonarLabs}
        height="60"
        className="d-inline-block mb-0"
        alt="Jonar labs logo"
      />
    </Navbar.Brand></Link>
    <div className="toggle"><Navbar.Toggle aria-controls="responsive-navbar-nav" /></div>
  
   
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="w-100"> 
    <div className="navLinkWrapper">
    <div className="navLHS">
    <Link to='/our-mission'  className="navItem" style={{color:this.props.linkColor}}>Our mission</Link>
    <Link to='/our-team'  className="navItem" style={{color:this.props.linkColor}}>Our team</Link>
    <Link to='/projects'  className="navItem" style={{color:this.props.linkColor}}>Our projects</Link>
    <Link to='/contact'  className="navItem" style={{color:this.props.linkColor}}>Contact us</Link>
    </div>
    <div className="navRHS"> 
      <Link to={frenchPage}  className="navItem" style={{color:this.props.linkColor}}>FR</Link> <Link to={englishPage} style={{color:this.props.linkColor}} className="navItem">EN</Link>
      </div>
    </div>
    </Nav>
   </Navbar.Collapse>
  </Navbar>
  </header>
)}};

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}


export default Header