import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import jonarLabs from '../images/labsLogo.png'

var frenchPage;
var englishPage;

class FrenchHeader extends React.Component {
  render() {
    if (typeof window !== 'undefined'){
    frenchPage=window.location.pathname;
    englishPage=window.location.pathname.slice(3);
    
  }
    
   
    return (
      <header>
    <Navbar bg="gray" variant="light" expand="md" id="top" className="bootyNav">
    <Link to="/fr"><Navbar.Brand className="ParagonERP">
    <img
        src={jonarLabs}
        height="60"
        className="d-inline-block mb-0"
        alt="Jonar labs logo"
      />
    </Navbar.Brand></Link>
    <div className="toggle"><Navbar.Toggle aria-controls="responsive-navbar-nav" /></div>
   
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="w-100"> 
    <div className="navLinkWrapper">
    <div className="navLHS">
    <Link to='/fr/our-mission'  className="navItem" style={{color:this.props.linkColor}}>Notre mission</Link>
    <Link to='/fr/our-team'  className="navItem" style={{color:this.props.linkColor}}>Notre equipe</Link>
    <Link to='/fr/projects'  className="navItem" style={{color:this.props.linkColor}}>Notre projets</Link>
    <Link to='/fr/contact'  className="navItem" style={{color:this.props.linkColor}}>Contactez-nous</Link>
    </div>
    <div className="navRHS"> 
      <Link to={frenchPage}  className="navItem" style={{color:this.props.linkColor}}>FR</Link> <Link to={englishPage} style={{color:this.props.linkColor}} className="navItem">EN</Link>
      </div>
    </div>
    </Nav>
   </Navbar.Collapse>
  </Navbar>
  </header>
    )
  }
}

FrenchHeader.propTypes = {
   siteTitle: PropTypes.string,
 }
FrenchHeader.defaultProps = {
 siteTitle: ``,
}

export default FrenchHeader